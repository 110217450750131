import map from 'lodash/map'
import {cancelSignal} from '~/workers/network.worker'

export default class RemoteDataSource {
  #page = 0
  #hasMore = 0
  /**
   *
   * @param {{}} props
   * @param {function} props.source
   * @param {{}} props.query
   * @param {{}} props.model
   * @param {string} props.cancelId
   *
   */
  constructor(props) {
    this.source = props.source
    this.query = props.query || {}
    this.model = props.model || Object
    this.cancelId = props.cancelId || null
    this.cancelCallId = null
  }

  get hasMoreAvailable() {
    return this.#hasMore
  }

  /**
   *
   * @param {{}} props
   */
  updateQuery(props) {
    Object.assign(this.query, props)
  }

  cancelCurrentQuery() {
    this.cancelCallId = null
    cancelSignal(this.cancelId)
  }

  resetQuery() {
    this.#page = 0
    this.#hasMore = true
  }

  async fetchNext({limit = 20, continuousCallback = function () {}} = {}) {
    const callId = Date.now()
    this.cancelCallId = callId

    const result = await this.source({
      query: {
        ...this.query,
        limit,
        offset: limit * this.#page
      },
      cancelId: this.cancelId
    })
    this.#page++
    this.#hasMore = result.hasMore

    // eslint-disable-next-line new-cap
    const results = map(result.data, item => new this.model(item))

    if(this.cancelCallId !== callId) {
      continuousCallback([])
      return []
    }

    continuousCallback(results)
  }

  /**
   *
   * @param {{}} data
   * @param {number} data.limit Fetch limit for items
   * @param {function} data.continuousCallback Continuous data return after each page fetch is done
   * @return {Promise<*>}
   */
  async fetchAll({limit = 20, continuousCallback = function () {}} = {limit: 20, continuousCallback: () => {}}) {
    cancelSignal(this.cancelId)
    const callId = Date.now()
    this.cancelCallId = callId

    const results = []
    let hasMore = true
    let page = 0

    while (hasMore && this.cancelCallId === callId) {
      const result = await this.source({
        query: {
          ...this.query,
          limit,
          offset: limit * page
        },
        cancelId: this.cancelId
      })

      page++
      hasMore = result.hasMore
      // eslint-disable-next-line new-cap
      results.push(...map(result.data, item => new this.model(item)))

      if(this.cancelCallId !== callId) {
        continuousCallback([])
        return []
      }

      continuousCallback(results)
    }

    let finalResults = results

    if(this.cancelCallId !== callId) finalResults = []

    this.cancelCallId = null
    return finalResults
  }
}
