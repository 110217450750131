
  import filter from 'lodash/filter';
  import find from 'lodash/find'
  import map from 'lodash/map'
  import sortBy from 'lodash/sortBy'
  import { mapGetters } from 'vuex'
  import isEmpty from 'lodash/isEmpty'
  import uniqBy from 'lodash/uniqBy'
  import TournamentBanner from '~/components/gaming-club/banners/TournamentBanner'
  import GCSwiper from '~/components/gaming-club/elements/GCSwiper.vue'
  import GCPageWrapper from '~/components/gaming-club/GCPageWrapper'
  import Tournament from '~/components/gaming-club/tournaments/Tournament'
  import Button from '~/components/general/elements/Button'
  import Row from '~/components/layout/Row'
  import MobileViewMixin from '~/configurations/mixins/MobileViewMixin'
  import CMSBanner from '~/database/models/CMSBanner'
  import {addWeeks, dayMonthYearISO} from '~/services/DateService'
  import LogService from '~/services/LogService'
  import {runTask} from '~/services/network/utils/axios'
  import { getMetaMockData } from '~/services/network/utils/helper.convenience.axios'
  import RemoteDataSource from '~/structures/dataSource/RemoteDataSource'
  import {getTournaments as getTournamentsWorker} from '~/workers/network.worker';
  import {getTournaments} from '~/services/network/utils/gamingClub.convenience.axios'
  import GCTournament from '~/database/models/GCTournament';
  import LogoMixin from '~/configurations/mixins/LogoMixin';
  import GCDiscordButton from '~/components/gaming-club/elements/GCDiscordButton.vue'
  import CMSBannerType from '@/database/models/CMSBannerType'

  /**
   * Filters a list of GCTournaments based on specific date condition and repetition.
   *
   * @param {GCTournament[]} tournaments - An array of GCTournament objects to be filtered.
   * @return {GCTournament[]} - An array of GCTournament objects that meet the specified conditions.
   */
  function filterEvents(tournaments) {
    const dateLimit = addWeeks(new Date(), 4)
    return filter(tournaments, tournament => {
      return !tournament.isRepeated || (tournament.isRepeated && new Date(tournament.date) < dateLimit)
    })
  }

  export default {
    name: 'GCTournamentsPage',
    components: {GCDiscordButton, GCSwiper, Tournament, Row, Button, TournamentBanner, GCPageWrapper },
    mixins: [LogoMixin, MobileViewMixin],
    inject: ['getLoc', 'getLocCurrency'],
    props: {
      leagueId: [Number, String]
    },
    async asyncData({params: {leagueId = null}, error}) {
      const tournaments = []
      let banners = []

      try {
        const dateBefore = addWeeks(new Date(), 4)
        const query = {
          date_after: dayMonthYearISO(),
          date_before: dayMonthYearISO(dateBefore.toISOString()),
          status: GCTournament.eligibleStatus(),
          o: 'date',
          limit: 100
        }
        let result = null

        if(process.client) {
          result = await runTask(getTournamentsWorker({
            query
          }))
        } else {
          result = await getTournaments({
            query
          })
        }

        banners = (await getMetaMockData({
          id: 'banners',
          query: {
            banner_type: CMSBannerType.playhouse
          }
        }))?.data || []
        tournaments.push(...result.data)
      } catch (e) {
        LogService.error(e)
      }

      return {
        banners,
        tournaments: {
          items: filterEvents(sortBy(map(tournaments, item => new GCTournament(item)), ['date', 'start']))
        }
      }
    },
    data () {
      return {
        banners: [],
        mobileToggle: {
          regular: true,
          irregular: true,
          options: [
            {
              id: 'regular',
              text: 'general_regular_time'
            },
            {
              id: 'irregular',
              text: 'general_irregular_time'
            }
          ]
        },
        tournaments: {
          items: []
        },
        expanded: []
      }
    },
    computed: {
      ...mapGetters({
        userCredit: 'authentication/userTotalCredit',
        isAuthenticated: 'authentication/isAuthenticated',
        playerProfilesForGames: 'gamingClub/playerProfilesForGames'
      }),
      mobileToggleValue(vm) {
        let value = vm.mobileToggle.irregular === vm.mobileToggle.regular

        if(!value) {
          value = vm.mobileToggle.irregular ? 'irregular' : 'regular'
        }

        return value
      },
      mobileTournaments(vm) {
        const value = vm.mobileToggleValue
        let tournaments = []

        if(['regular', 'irregular'].includes(value)) {
          tournaments = value === 'regular' ? vm.repeatedTournaments : vm.nonRepeatedTournaments
        } else {
          tournaments = [...vm.repeatedTournaments, ...vm.nonRepeatedTournaments]
          tournaments = sortBy(tournaments, tournament => +(new Date(tournament.date)))
        }

        return tournaments
      },

      repeatedTournaments(vm) {
        return filter(vm.tournaments.items, item => item.isRepeated)
      },
      nonRepeatedTournaments(vm) {
        return filter(vm.tournaments.items, item => !item.isRepeated)
      },
      featuredTournaments(vm) {
        const tournaments = filter(vm.tournaments.items, item => item.featured)
        return tournaments.slice(0, 3)
      },
      hasBannersSerialized(vm) {
        return vm.bannersSerialized.length > 0
      },
      bannersSerialized(vm) {
        const banners = map(vm.banners, banner => ({
          id: banner.id,
          hasSignIn: false,
          bannerLoc: banner.imageUrlLoc,
          bannerSmallLoc: banner.imageUrlMobileLoc,
          buttonLoc: banner.buttonLoc,
          buttonMobileLoc: banner.buttonMobileLoc,
          link: banner.link,
          external: banner.isLinkExternal,
        }))
        const tournamentBanners = map(vm.featuredTournaments, tournament => ({
          id: tournament.id,
          hasSignIn: tournament.hasSignIn,
          bannerLoc: tournament.bannerLoc,
          bannerSmallLoc: tournament.bannerSmallLoc
        }))

        return [...banners, ...tournamentBanners]
      },
    },
    created() {
      const dateBefore = addWeeks(new Date(), 4)
      this.banners = map(this.banners, item => new CMSBanner(item))
      this.tournaments.items = map(this.tournaments.items, item => new GCTournament(item))
      this.tournaments.dataSource = new RemoteDataSource({
        source: getTournamentsWorker,
        model: GCTournament,
        query: {
          tournament_format: null,
          game: null,
          date_after: dayMonthYearISO(),
          date_before: dayMonthYearISO(dateBefore.toISOString()),
          status: GCTournament.eligibleStatus(),
          o: 'date',
        },
        cancelId: `user-tournaments-page-tournaments-fetch`
      })
    },
    beforeMount() {
      const oThis = this
      runTask(oThis.tournaments.dataSource.fetchAll({
        limit: 50,
        continuousCallback(items) {
          oThis.tournaments.items = filterEvents(sortBy(uniqBy(oThis.tournaments.items.concat(items), 'id'), ['date', 'start']))
        }
      }))
    },
    methods: {
      hasContentValue(value) {
        return value && !isEmpty(value)
      },

      expandItem(id) {
        if(this.expanded.includes(id)) {
          this.expanded = filter(this.expanded, expanded => expanded !== id)
        } else {
          const items = [...this.expanded, id]
          this.expanded = items
        }
      },

      enrollAction(id) {
        const oThis = this
        const tournament = find(this.tournaments.items, item => item.id === id)

        if(tournament) {
          this.$modal.showGCTournamentEnrollForm({
            tournament,
            userCredit: this.userCredit,
            listeners: {
              dismiss: () => {oThis.$modal.hide()},
              success: () => {
                oThis.$modal.hide()
                oThis.$refs?.listResolver?.completeRefresh()
              }
            }
          })
        }
      },

      disenrollAction(id) {
        const oThis = this
        const tournament = find(this.tournaments.items, item => item.id === id)

        if(tournament) {
          this.$modal.showGCTournamentDisenrollForm({
            tournament,
            userCredit: this.userCredit,
            listeners: {
              dismiss: () => {oThis.$modal.hide()},
              success: () => {
                oThis.$modal.hide()
                oThis.$refs?.listResolver?.completeRefresh()
              }
            }
          })
        }
      }
    }
  }
