import EventBus, {BusEvents} from '~/services/eventBus/EventBus'

export default {
  data() {
    return {
      mobileView: false,
      mobileViewBreakpoint: 991
    }
  },
  mounted() {
    this.windowSizeChanged({width: window.innerWidth})
    EventBus.$on(BusEvents.app.windowResize, this.windowSizeChanged)
  },
  beforeDestroy() {
    EventBus.$off(BusEvents.app.windowResize, this.windowSizeChanged)
  },
  methods: {
    windowSizeChanged({width}) {
      this.mobileView = width < this.mobileViewBreakpoint
    },
  }
}
