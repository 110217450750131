
import Row from '~/components/layout/Row.vue'

export default {
  name: 'GCDiscordButton',
  components: { Row },
  data() {
    return {
      link: this.$themeSettings.social.links.discord,
    }
  },
}
