
  import Button from '~/components/general/elements/Button'

  export default {
    name: 'TournamentBanner',
    functional: true,
    components: { Button },
    props: {
      banner: String,
      bannerSmall: String,
      enroll: Boolean
    }
  }
