
import Row from '~/components/layout/Row'
import ListLoadIndicator from '~/components/general/elements/ListLoadIndicator.vue';

export default {
  name: 'GCPageWrapper',
  functional: true,
  components: {ListLoadIndicator, Row},
  props: {
    title: String,
    loading: Boolean
  }
}
