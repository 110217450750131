
  import LogoMixin from '~/configurations/mixins/LogoMixin'

  export default {
    name: 'GCSwiper',
    mixins: [LogoMixin],
    inject: ['getLoc'],
    props: {
      items: Array
    },
    emits: ['indexChanged'],
    data () {
      return {
        activeIndex: 0,
        swiperOptions: {
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          loop: false,
          initialSlide: 0,
          watchOverflow: true,
          preloadImages: false,
          lazy: {
            loadOnTransitionStart: true
          },
          watchSlidesVisibility: true,
          preventInteractionOnTransition: true,
          roundLengths: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          }
        }
      }
    },
    computed: {
      itemsLength(vm) {
        return vm.items.length
      },
      showControls (vm) {
        return vm.itemsLength > 1
      },
      hidePrevious(vm) {
        return vm.activeIndex === 0
      },
      hideNext(vm) {
        return vm.activeIndex === (vm.itemsLength - 1)
      }
    },
    methods: {
      next () {
        this.swiper.slideNext(500)
      },
      back () {
        this.swiper.slidePrev(500)
      },

      setSlide (index) {
        if(this.swiper) {
          this.swiper.slideToLoop(index)
        }
      },

      slideChange(swiper) {
        const index = swiper.realIndex
        this.activeIndex = index
        this.$emit('indexChanged', index)
      },
    }
  }
